import * as React from 'react';

export const ShoppingBagIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="15"
    viewBox="0 0 16 15"
  >
    <path d="M1.15555556,7 L1,7 C0.44771525,7 0,6.55228475 0,6 C0,5.44771525 0.44771525,5 1,5 L3.05025253,5 L3,4.94974747 L7.24264069,0.707106781 C7.63316498,0.316582489 8.26632996,0.316582489 8.65685425,0.707106781 L12.8994949,4.94974747 L12.8492424,5 L15,5 C15.5522847,5 16,5.44771525 16,6 C16,6.55228475 15.5522847,7 15,7 L14.8444444,7 L13.8632912,13.307414 C13.711755,14.2815752 12.8729351,15 11.8870582,15 L4.11294178,15 C3.1270649,15 2.28824503,14.2815752 2.13670885,13.307414 L1.15555556,7 Z M10.4748737,5 L7.94974747,2.47487373 L5.4246212,5 L10.4748737,5 Z M5.64704761,8.08518543 C5.38031456,8.01371453 5.10614608,8.17200577 5.03467518,8.43873882 L3.999399,12.3024421 C3.92792809,12.5691752 4.08621934,12.8433437 4.35295239,12.9148146 C4.61968544,12.9862855 4.89385392,12.8279942 4.96532482,12.5612612 L6.000601,8.69755787 C6.07207191,8.43082482 5.91378066,8.15665634 5.64704761,8.08518543 Z M8.64704761,8.08518543 C8.38031456,8.01371453 8.10614608,8.17200577 8.03467518,8.43873882 L6.999399,12.3024421 C6.92792809,12.5691752 7.08621934,12.8433437 7.35295239,12.9148146 C7.61968544,12.9862855 7.89385392,12.8279942 7.96532482,12.5612612 L9.000601,8.69755787 C9.07207191,8.43082482 8.91378066,8.15665634 8.64704761,8.08518543 Z M11.6470476,8.08518543 C11.3803146,8.01371453 11.1061461,8.17200577 11.0346752,8.43873882 L9.999399,12.3024421 C9.92792809,12.5691752 10.0862193,12.8433437 10.3529524,12.9148146 C10.6196854,12.9862855 10.8938539,12.8279942 10.9653248,12.5612612 L12.000601,8.69755787 C12.0720719,8.43082482 11.9137807,8.15665634 11.6470476,8.08518543 Z" />
  </svg>
);
