import * as React from 'react';

export function ProductIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
    >
      <path d="M1,0 L6,0 C6.55228475,0 7,0.44771525 7,1 L7,6 C7,6.55228475 6.55228475,7 6,7 L1,7 C0.44771525,7 0,6.55228475 0,6 L0,1 C0,0.44771525 0.44771525,0 1,0 Z M10,0 L15,0 C15.5522847,0 16,0.44771525 16,1 L16,6 C16,6.55228475 15.5522847,7 15,7 L10,7 C9.44771525,7 9,6.55228475 9,6 L9,1 C9,0.44771525 9.44771525,0 10,0 Z M12.5,9 C14.4329966,9 16,10.5670034 16,12.5 C16,14.4329966 14.4329966,16 12.5,16 C10.5670034,16 9,14.4329966 9,12.5 C9,10.5670034 10.5670034,9 12.5,9 Z M1,9 L6,9 C6.55228475,9 7,9.44771525 7,10 L7,15 C7,15.5522847 6.55228475,16 6,16 L1,16 C0.44771525,16 0,15.5522847 0,15 L0,10 C0,9.44771525 0.44771525,9 1,9 Z" />
    </svg>
  );
}
