import * as React from 'react';

export function TransactionIcon(): JSX.Element {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="18"
      viewBox="0 0 16 18"
    >
      <path d="M11.6850112,6 L5,6 C3.34314575,6 2,7.34314575 2,9 L2,10 L0,10 L0,9 C0,6.23857625 2.23857625,4 5,4 L11.6850112,4 L10.6073147,3.49025739 L8.67276818,1.50221105 L10.1345536,0 L15,5 L10.1345536,10 L8.67276818,8.49778895 L10.6641888,6.45129568 L11.6850112,6 Z M4.31498875,12 L11,12 C12.6568542,12 14,10.6568542 14,9 L14,8 L16,8 L16,9 C16,11.7614237 13.7614237,14 11,14 L4.31498875,14 L5.39268526,14.5097426 L7.32723182,16.497789 L5.86544636,18 L1,13 L5.86544636,8 L7.32723182,9.50221105 L5.33581117,11.5487043 L4.31498875,12 Z" />
    </svg>
  );
}
